<template>
  <div class="card">
    <h2 class="card-title">Listado de noticias</h2>
    <div v-if="noticias">
      <table-list
        ref="tablelist"
        :showFilter="true"
        :data="noticias"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        @rowClicked="verNoticia"
      ></table-list>
    </div>
  </div>
</template>
<script>
import TableList from "@/components/elements/TableList";
import { NoticiasServices } from "../services/NoticiasServices";

export default {
  name: "ListarNoticias",
  components: { TableList },
  data() {
    return {
      noticias: null,
      headers: NoticiasServices.headers(),
      body: NoticiasServices.body(),
      actionButton: {
        text: "Nueva noticia",
        action: () => {
          this.$router.push({name: 'CrearNoticia'})
        },
      },
    };
  },
  mounted(){
      NoticiasServices.api.getAll().then( response => this.noticias = response.noticias)
  },
  methods:{
    verNoticia(noticia){
      this.$router.push({ name: 'VerNoticia', params:{ id: noticia.id } })
    }
  }
};
</script>
